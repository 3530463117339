import { inject, Injectable } from '@angular/core';
import { Auth, authState, sendPasswordResetEmail, user, User } from '@angular/fire/auth';
import { deleteField, doc, docData, Firestore, setDoc, updateDoc } from '@angular/fire/firestore';
import { signInWithEmailAndPassword } from '@firebase/auth';
import { BehaviorSubject, map, Observable, of, switchMap } from 'rxjs';

import { PathConstants } from '../db.paths';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private userSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private afAuth = inject(Auth);
  private authState$ = authState(this.afAuth);
  private firestore = inject(Firestore);
  referToAttorneys: boolean = false;

  constructor() {
    this.authState$
      .pipe(
        switchMap((user: User | null) => {
          // console.log('[AuthService] authState - user:', user);
          if (!user) {
            return of(null);
          }
          const path = PathConstants.getUserDetailsPath(user.uid);
          // console.log('[AuthService] authState - path:', path);
          // TODO: check why we are using metadata
          const docRef = doc(this.firestore, path);
          return docData<any>(docRef, { idField: 'id' }).pipe(
            map((userData) => ({ ...userData, metadata: user.metadata }))
          );
        })
      )
      .subscribe((user) => {
        console.log('[AuthService] authState - user:', user);
        // this.orgCode = user?.orgCode;
        this.userSubject.next(user);
      });
  }

  updateUserIfo(user: any, uid: any) {
    console.log(user, 'updates user info');
    const path = PathConstants.getUserDetailsPath(uid);
    const userDocRef = doc(this.firestore, path);
    return updateDoc(userDocRef, user);
  }

  updateUserSettings(uid: string, settings: any) {
    const path = PathConstants.getUserDetailsPath(uid);
    const userDocRef = doc(this.firestore, path);

    return updateDoc(userDocRef, {
      [`settings.appNotification`]: settings.appNotification,
      [`settings.emailNotification`]: settings.emailNotification,
      [`settings.smsNotification`]: settings.smsNotification,
    });
  }

  addUserSign(signature: any, uid: any) {
    const path = PathConstants.getUserDetailsPath(uid);
    const userDocRef = doc(this.firestore, path);
    const dataToUpdate = { esignData: signature };

    return setDoc(userDocRef, dataToUpdate, { merge: true });
  }

  deleteUserSign(uid: string) {
    const path = PathConstants.getUserDetailsPath(uid);
    const userDocRef = doc(this.firestore, path);

    const dataToUpdate = {
      esignData: deleteField(),
    };

    return updateDoc(userDocRef, dataToUpdate);
  }

  getLoggedInUser(): Observable<any> {
    return this.userSubject.asObservable();
  }

  isValidLogin(): Observable<boolean> {
    return user(this.afAuth).pipe(map((user) => !!user));
  }

  login(email: string, password: string) {
    return signInWithEmailAndPassword(this.afAuth, email, password);
  }

  logout() {
    return this.afAuth.signOut().then(() => {
      this.userSubject.next(null);
    });
  }

  resetPassword(email: string) {
    return sendPasswordResetEmail(this.afAuth, email);
  }
}
